import Header from 'components/Header';
import Footer from 'components/Footer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import articles from '../../services/articles';
import Head from './Head';

const Page404 = ({ props }) => {
  return (
    <>
      <Head props={props} />
      <Header />
      <header className='pt-10'>
        <div className='container'>
          <div className='text-center col-12 col-sm-12 col-lg-10 col-xl-9 mx-auto position-relative z-index-20'>
            <h1 className='display-3 fw-bold mb-3'>
              Nenhum conteúdo encontrado
            </h1>
          </div>
        </div>
      </header>
      <div className='container position-relative z-index-20'>
        <div className='row gx-8 align-items-center'>
          <div className='col-0 col-lg-2'></div>
          <div className='col-12 col-lg-8'>
            <h2 className='display-5 fw-bold mb-2'>
              Pesquise em nossas postagens
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Page404;
