import utils from '../../utils';

const Head = () => {
  return (
    <>
      {utils.generateMetatags({
        url: '/',
        title: 'Blog Codeeweb | 404',
        description: 'Nenhum conteúdo encontrado teste procurar outro post',
        color: '#2c4bff',
      })}
    </>
  );
};

export default Head;
